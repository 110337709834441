import React from 'react'
import { Link } from 'gatsby'
import { Col, Row, Stack } from 'react-bootstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight } from '@fortawesome/free-solid-svg-icons'
import Layout from '/src/components/layout'
import HeroImage from '/src/components/layout/HeroImage'
import { ForgotPasswordForm } from '/src/components/user'

const ForgotPasswordPage = () => {
  return (
    <Layout hero={<HeroImage title="Reset Password" />}>
      <Row className="d-flex justify-content-center">
        <Col md={10} lg={8}>
          <Row className="mt-2 mb-4">
            <Col sm={6}>
              <div className="page-title">
                <h1 className="title">Reset Password</h1>
              </div>
            </Col>
            <Col sm={6} className="d-flex justify-content-end">
              <Stack direction="horizontal" gap={4}>
                <label className="text-blue">Need an account?</label>
                <Link className="button outline" to="/user/register">
                  Sign up
                  <FontAwesomeIcon icon={faArrowRight} />
                </Link>
              </Stack>
            </Col>
          </Row>
          <ForgotPasswordForm />
        </Col>
      </Row>
    </Layout>
  )
}

export default ForgotPasswordPage

export const Head = () => <title>Forgot Password | Orrcon Steel</title>
