import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { Container, Row } from 'react-bootstrap'

export default ({ title }) => {
  return (
    <div className="hero mb-5">
      <Container fluid="xxl">
        <Row className="d-flex align-items-center">
          <div className="page-hero">
            <div className="page-title">
              <h1>{title}</h1>
            </div>

            <StaticImage 
              src="../../images/orrcon-team.jpeg" 
              alt="Hero image"
              width={1420}
              height={250}
              placeholder="blurred"
              layout="fixed" />
          </div>
        </Row>
      </Container>
    </div>
  )
}
